import React from "react";
import Layout from "../shared/layout";
import { withRouter } from 'react-router-dom';
import './sign-up.styles.scss';

const SignUp = (members) => {
    return (
        <Layout>
            <div className="sign-up">
                <h5>Links will become entirely avaiable in our Cell Phase - 1a Online Member Launch.</h5>
                <h5>Until then, learn what Lomb Co. will provide below:</h5>
                <div>Lomb Co. is designing the new Meta in IOT Technologies.</div>
                <div>Helping you, our Members, find the Current Pulse in Society.</div>
                <div>RePossess your Time while Regaining Intuitions through Brief Thought Provoking write ups IN-directly proportioned for you.</div>
                <div>Interact with Opportunity Daily, Simply.</div>
                <div>Let Lomb Co. help you become your Best You more Readily with Simple Incite.</div>
                <div>Join us in Shifting the Dialogue toward Promoting a Better Planet.</div>
                <br />
                <h5>At Lomb Co. the Number One Rule of Science is the Number One Rule of Spelling.</h5>
                <h5>Eye before E except after C: Science.</h5>
                <div>Join us for an Experience toward the Aether.</div>

            </div>
        </Layout>
    )
}

export default withRouter(SignUp);