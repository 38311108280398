import React from "react";
import { withRouter } from "react-router-dom";
import studioBag from "../../assets/finke.jpeg";
import "./main-section.styles.scss";

const MainSection = ({ history }) => {
  return (
    <div className="main-section">
      <div className="main-section-middle">
        <div className="main-section-image">
          <img src={studioBag} alt="deerhead" />
        </div>
        <div className="main-section-description">
          <h2>Designed to Culture.
            <br /> Crafted for Sense</h2>
          <p>
            Lomb Co. makes products that effortlessly improve your day. <br />
            From simple aesthetic to noted appreciation thRu thoughtful reflection, every product
            &#955;older <br /> Thoughtfully partakes toward a better ARTiculated culture. <br /><br />
            Promoting a Naturally Hea(l)thier world. <br />
            With balance of form and function.<br /> Partake and recieve as much <br />
            || <br />little as you prefer with every
            use.
          </p>
          <div className="buzzton">
            <button
              className="join-now"
              onClick={() => history.push("/sign-up")}
            >
              Catch the Buzz
            </button>
          </div>
          
        </div>
        <br />
      </div>
    </div>
  );
};
export default withRouter(MainSection);
