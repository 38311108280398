import React from "react";
import { withRouter} from "react-router-dom";
import "./featured-phase.styles.scss";
import Img1 from "../../assets/phase-imgs/cell-stage.jpg";
import Img2 from "../../assets/phase-imgs/predator-stage.jpg";
import Img3 from "../../assets/phase-imgs/cohesion-stage.jpg";
import Img4 from "../../assets/phase-imgs/family-stage.jpg";

let Img = [Img1, Img2, Img3, Img4];  


const FeaturedPhase = (props) => {
  const { title, image, quantity, history, id, description } = props;
  const phase = { title, image, quantity, id, description };

  return (
    
    <div  key={phase.id}
      className="retain-scale featured-phase "
      onClick={() => history.push(`/road-map-detail/${id}`)}
    >
      
      
      <div className="phase-image">
        <img src={Img[id-1]} alt="product" />
      </div>
      <div className="phase-content">
        <h3>{title}</h3>
        <h5>{description}</h5>
        <p>Quantity of Release: {quantity} units</p>
        <br />
        <b><u>Click for More Info</u></b>
      </div>
    </div>
  );
};

export default withRouter(FeaturedPhase);