import React from "react";
import ROADMAP_DATA from "../road-map";
import { withRouter} from "react-router-dom";
import Layout from "../shared/layout";
import ImgArt from '../../assets/LombCoInitialRoadMap.png';
import "./road-map-detail.styles.scss";

const RoadMapDetail = () => {
    
    let match;
    const loadPageData = ROADMAP_DATA;
    const pageId = Number(window.location.href.split('/').pop());  
    for(let i = 0; i < loadPageData.length; i++) {
        if(pageId === loadPageData[i].id) {
            match = loadPageData[i];
        }
    }
    //Need to offload writeup if not objected correct

    return (
        <Layout>
            <div className="road-map-detail-container">
                <div className="road-map-art">
                    <h2>{match.title}</h2>
                    <h3>{match.description}</h3>
                    <img src={ImgArt} alt="RoadMap" className="roadmap-img" />    
                </div>
                <div className="road-detail-writeup">
                    
                    
                    <ul>{match.writeup.map((str) => <li className={str.class}>{str.content}</li>)}</ul>
                </div>
            </div>
        </Layout>
    )
}

export default withRouter(RoadMapDetail);