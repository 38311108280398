import React, { useContext } from "react";
//import { UsersContext } from "../../context/user-context";
import Header from "../header/header";
import Footer from "../footer/footer";

const Layout = ({ children }) => {
  //const { member } = useContext(UsersContext);
  //console.log(member);
  return (
    <>
      <Header value={{ /*member*/ }} />
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
