import { Switch, Route } from "react-router-dom";
import HomePage from "./components/home-page";
import NotFound from "./components/not-found";
import roadMapDetail from "./components/road-map-detail/road-map-detail";
import SignUp from "./components/sign-up/sign-up";
import "./App.scss";


function App() {
  return (
    <div className="App">
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path="/road-map-detail" component={roadMapDetail} />
        <Route path="/sign-up" component={SignUp} />
        <Route path="*" component={NotFound} />
      </Switch>
    </div>
  );
}

export default App;
