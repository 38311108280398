import React from "react";
import { withRouter } from "react-router-dom";
import "./hero.styles.scss";

const Hero = ({ history }) => {
  return (
    <section className="hero-image">
      <div className="hero-center">
        <div className="hero-content">
          <h1 className="hero-title">A Better Routine</h1>
          <button className="join-now" id="btn-hero" onClick={() => history.push("/sign-up")}>Join In</button>
        </div>
      </div>
    </section>
  );
};

export default withRouter(Hero);
