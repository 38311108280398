import React, {useContext, useState} from "react";
import { Link } from "react-router-dom";
import CartIcon from "../cart-icon/cart-icon";
import "./header.styles.scss";

let togl = null;
//let img = userIcon;
/*const runSwitch = () => {
  console.log(togl);
  if(togl === null) {
    togl = false;
    img = userSignOut;
    console.log(togl);
  }
  else if (togl === true) {
    togl = false;
    img = userSignOut;
    console.log(togl);
  }
  else if (togl === false) {
    togl = true;
    img = userIcon;
    console.log(togl);
  }
}*/

//runswitch2 only triggers for true cause member is passed in static data now
/*const runSwitch2 = (member) => {
  console.log("switch 2 member passed like this: ", member);
  if(member === true) {
    togl = false;
    img = userIcon;
    console.log(togl);
  }
  else {
    togl = true;
    img = userSignOut;
    console.log(togl);
  }
}*/

const Header = (props) => {
  
  //Correct component use of UsersContext
  //const member = useContext(UsersContext);
  //console.log("UsersContext taken from user context to get member for check login in header: ",member);
  
  //runSwitch2(member)
  //only ran on click could be of issue... want autolog save
  return (
    <nav className="nav-menu">
      <div className="logo">
        <Link to="/">Lomb Co</Link>
      </div>
      <ul className="links">
        <li>
          <h4>Welcome to our PreSale Lander</h4>
        </li>
        
        <li>
            
        </li>
        {
          /*
          !togl && 
          <li>
            <Link to="/sign-in">Sign In</Link>
          </li>
          */
        }

      </ul>
      <ul className="cart-container">
        <li>
          <CartIcon />
        </li>
      </ul>
      
      
      
    </nav>
  );
};

export default Header;
