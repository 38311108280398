const ROADMAP_DATA = [
    {
      id: 1,
      title: "Phase 1a - Cell Stage",
      description: "Initial Funding PreRelease",
      image: "require(../../assets/phase-imgs/cell-stage.jpg)",
      quantity: 100,
      writeup: [
        {
          "class" : "basicTopic1",
          "content" : "This will be the Initial Funding and Base Metric Test Line of Lomb Co Products:",
        },
        {
          "class" : "basicTopic1",
          "content" : "Seed Stage, 1a",
        },
        {
          "class" : "basicLine1",
          "content" : <br />,
        },
        {
          "class" : "basicLine1",
          "content" : <br />,
        },
        {
          "class" : "basicLine1",
          "content" : "This first line of sales will ensure customer interaction.",
        },
        {
          "class" : "basicLine1",
          "content" : "Accounting for the use capabilities throughout our Member Systems.",
        },
        {
          "class" : "basicLine2",
          "content" : "Including:",
        },
        {
          "class" : "basicLine3",
          "content" : "Sign Up Thru Purchase, ",
        },
        {
          "class" : "basicLine3",
          "content" : "Account Retrievability, ",
        },
        {
          "class" : "basicLine3",
          "content" : "Updating/Recovering Credentials, ",
        },
        {
          "class" : "basicLine3",
          "content" : "Current Metric Tracking, "
        },
        {
          "class" : "basicLine3",
          "content" :  "etc.",
        },
        {
          "class" : "basicLine1",
          "content" : <br />,
        },
        {
          "class" : "basicLine1",
          "content" : <br />,
        },
        {
          "class" : "basicLine1",
          "content" : "This initial stage will ensure Lomb Co. Members recieve reliable User Interfaces for effective daily interaction.",
        },
        {
          "class" : "basicLine1",
          "content" : <br />,
        },
        {
          "class" : "basicLine1",
          "content" : <br />,
        },
        {
          "class" : "basicLine1",
          "content" : <br />,
        },
        {
          "class" : "basicLine1",
          "content" : <br />,
        },
        {
          "class" : "basicLine1",
          "content" : <br />,
        },
        {
          "class" : "basicLine1",
          "content" : "Every Lomb Co. Product will be sold with a Discrete Integrity. Stored Relative to your Member Account.",
        },
        {
          "class" : "basicLine1",
          "content" : <br />,
        },
        {
          "class" : "basicTopic1",
          "content" : "Allowing for Appreciable Collections.",
        },
        {
          "class" : "basicLine1",
          "content" : <br />,
        },
        {
          "class" : "basicLine1",
          "content" : "A purchase of Line 1a will Secure Member Functional about All Data Records.",
        },
        {
          "class" : "basicLine1",
          "content" : "Used to then Deliver our Future Aspirations w/ Greater Security in a Known Reliable Manner.",
        },
        {
          "class" : "basicLine1",
          "content" : <br />,
        },
        {
          "class" : "basicLine1",
          "content" : "If you appreciate: ",
        },
        {
          "class" : "basicLine3",
          "content" : "Creativity and Art, ",
        },
        {
          "class" : "basicLine3",
          "content" : "Education made Simple,",
        },
        {
          "class" : "basicLine3",
          "content" : "Promoting Healthy Culture,",
        },
        {
          "class" : "basicLine3",
          "content" : "Elevating Common Sense,",
        },
        {
          "class" : "basicLine3",
          "content" : "etc.",
        },
        {
          "class" : "basicLine1",
          "content" : <br />,
        },
        {
          "class" : "basicLine1",
          "content" : "Please purchase to help fund Our initiative (in)&directly.",
        },
        {
          "class" : "basicLine1",
          "content" : <br />,
        },
        {
          "class" : "basicLine1",
          "content" : "In exchange you'll obtain Better Interactions DAILY and the Secrets of the Universe...",
        },
        {
          "class" : "basicLine1",
          "content" : <br />,
        },
        {
          "class" : "basicTopic1",
          "content" : "Don't Miss Out!",
        },
      ]
    },
    {
      id: 2,
      title: "Phase 1b - Predator Stage",
      description: "Second Collection with Initial Member Feedback Loop",
      image: "../../assets/phase-imgs/predator-stage.jpg",
      quantity: 100,
      writeup: [
        {
          "class" : "basicTopic1",
          "content" : "This Second collection will help further develop the initial Lomb Co. product.",
        },
        {
          "class" : "basicLine1",
          "content": "Now displaying Daily Member Interaction & Usage Features."
        },
        {
          "class" : "basicLine2",
          "content" : "Our initial Funding Stage 1a established a working model.",
        },
        {
          "class" : "basicLine1",
          "content" : "Now in 1b, we elaborate that model with inclusive daily information and interaction features.",
        },
        {
          "class" : "basicLine1",
          "content" : "Improving every Lomb Co. Member's daily habits.",
        },
        {
          "class" : "basicLine2",
          "content" : "These will not be demanding or laborous interactions.",
        },
        {
          "class" : "basicLine1",
          "content" : "The aim is to provide a digital location to briefly escape or plan your day.",
        },
        {
          "class" : "basicLine1",
          "content" : "While effectively and readily elevating your mind.",
        },
        {
          "class" : "basicLine2",
          "content" : "The Lomb Co. initiative will hopefully Promote continuous use with a return simply aimed toward forming a Better You",
        },
        {
          "class" : "basicLine4",
          "content" : "To note, this second collection will also be of unique design, for any avid appreciators or collectors looking to expand their Lomb Co. Collection",
        },
        {
          "class" : "basicLine2",
          "content" : "At stage 1a we found our footing.  Now with 1b we turn our eyes toward the Aether.",
        },
      ]
    },
    {
      id: 3,
      title: "Phase 25 - Cohesion Stage",
      description: "First Scaled Release with Proven & Tested+Working Demo \nEstablished New Member Features \nFeedback Loops for more Involved Experience",
      image: "../../assets/phase-imgs/cohesion-stage.jpg",
      quantity: 200,
      writeup: [
        {
          "class" : "basicTopic1",
          "content" : "The Cohesion Stage 25 will be the first Scaled Release with Proven & Tested + Working Demo: Established",
        }, 
        {
          "class" : "basicLine",
          "content" : "w/ New Member Features and Feedback Loops for more involved Experience will be Provided.",
        }, 
        {
          "class" : "basicLine",
          "content" : "This is where the Real Buzz starts, and the best part is:",
        }, 
        {
          "class" : "basicLine",
          "content" : "with even minimal effort or contribution, online or about other Lomb Co. Members, the benefits will remain consistently yours.",
        }, 
        {
          "class" : "basicLine",
          "content" : "The aim has shifted, into high gear, and the Convesation is Becoming Whatever, or so 90s.",
        }, 
        {
          "class" : "basicLine",
          "content" : "Time is a little less relevant.  Everything is Out Dated.",
        }, 
        {
          "class" : "basicLine",
          "content" : "The Predicate starts to become a little less clear... Suddenly Our windows are open once again.",
        }, 
      ]
    },
    {
      id: 4,
      title: "Phase 35a - Family Stage",
      description: "Expansion Wave for Live Current Growth \nAnd More Elementary Features to Enhance Relative Buzz",
      image: "../../assets/phase-imgs/family-stage.jpg",
      quantity: 300,
      writeup: [
        {
          "class" : "basicTopic1",
          "content" : "It wouldn't be appropriate to count this many chickens for Pre-Sale, check back as Cohesion Stage is kicking off.",
        }, 
        {
          "class" : "basicLine",
          "content" :"There are many ideas on the table but in proper order may depend on a little more forethought to relative demand in scaling their production.",
        }, 
      ]
    },
  ];
  
  export default ROADMAP_DATA;