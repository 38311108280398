import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import shoppingBag from "../../assets/shopping-bag.png";
import "./cart-icon.styles.scss";

const CartIcon = ({ history }) => {
  //const { itemCount, cartItems } = useContext(CartContext);
  //console.log("CartItems:", cartItems);
  return (
    <div onClick={() => history.push("/sign-up")}>
      <img src={shoppingBag} alt="shopping-cart-icon" />
    </div>
  );
};

export default withRouter(CartIcon);
