import React from "react";
import Layout from "./shared/layout";
import Hero from "./hero/hero";
import MainSection from "./main-section/main-section";
import RoadMap2 from "./road-map/road-map2";

const HomePage = () => {
  return (
    <>
      <Layout>
        <Hero />
        <MainSection />
        <RoadMap2 />
      </Layout>
    </>
  );
};

export default HomePage;
