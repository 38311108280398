import React from "react";
import ROADMAP_DATA from "../road-map";
import FeaturedPhase from "../shared/featured-phase";
import "./road-map2.styles.scss";



const RoadMap2 = () => {
  const phaseListItems = ROADMAP_DATA
    .filter((phase, i) => i < 4)
    .map((phase) => <FeaturedPhase {...phase} key={phase.id} />);
  return (
    <div className="road-image">
      <div className="phase-containers">
        <h1 className="phase-title">NaTur<u>Al</u>-Ro<u>AD</u>Map ENc<u>l</u>0<u>U</u>d-in<u>G</u>s</h1>
        <div className="wrapper">
          <div className="phases">{phaseListItems}</div>
        </div>
      </div>
    </div>
  );
};

export default RoadMap2;